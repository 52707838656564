import { useTranslation } from 'react-i18next'
import { fromMaybe, isJust, Just, Nothing } from 'sanctuary'

import { configuration } from 'common/configuration'
import { ISO_639_1 } from 'common/translations/locales'
import { TranslationsConstants } from 'common/translations/TranslationsConstants'

const { defaultLanguage, supportedLanguages } = configuration

export function isSupportedLanguage(candidate: string | undefined | null): boolean {
  return candidate != null && supportedLanguages.some(lang => lang === candidate);
}

// getLanguageFromQueryIfExists ::  Router -> Maybe<ISO_639_1>
export const getLanguageFromQueryIfExists = (query: URLSearchParams) => {
  const lang = query.get(TranslationsConstants.LANG)
  if (lang != null && isSupportedLanguage(lang)) {
    return Just(<ISO_639_1>lang)
  }
  return Nothing
}

/**
 * getLanguage returns the supported language to use
 * (either from the URL, local storage, browser preferrence or the default one)
 * @param iNavigator window.navigator to inject
 * @param iStorage localStorage interface to inject
 * @param query URLSearchParams optional interface to inject
 * @returns ISO_639_1 language to use or the default one
 */
export function getLanguage(iNavigator: Navigator, iStorage: Storage, query?: URLSearchParams): ISO_639_1 {
  // if there is an explicit language passed
  if (query != null) {
    const fromQuery = getLanguageFromQueryIfExists(query)
    if (isJust(fromQuery)) {
      const lang = fromMaybe(defaultLanguage)(fromQuery)
      iStorage.setItem(TranslationsConstants.LANG, lang)
      return lang
    }
  }

  // check if a preference is persisted on the localStorage
  const preferredLanguage = iStorage.getItem(TranslationsConstants.PREFERRED_LANGUAGE)
  if (isSupportedLanguage(preferredLanguage)) {
    return (preferredLanguage as ISO_639_1)
  }

  // check the if the browser preferred language is supported
  // TODO use sanctuary get for the missing null checks here...
  const [browserLanguage] = iNavigator.language.split('-');
  if (isSupportedLanguage(browserLanguage)) {
    return (browserLanguage as ISO_639_1)
  }

  // resort the configuration's default language
  return (defaultLanguage as ISO_639_1)
}

export function useLanguage() {
  const { i18n } = useTranslation()
  const query = new URLSearchParams(window.location.search)

  function configureLanguage() {
    const lang = getLanguage(navigator, localStorage, query)
    i18n.changeLanguage(lang)
  }

  return {
    getLang: () => getLanguage(navigator, localStorage, query),
    configureLanguage,
  }
}
