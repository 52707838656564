// lw-aspect logger
/* eslint-disable no-console */

import { DateTime } from 'luxon'

export enum LOG_LEVELS {
  ERROR = "ERROR",
  WARN = "WARN",
  INFO = "INFO",
  VERBOSE = "VERBOSE",
  DEBUG = "DEBUG",
}

const DEBUG = [LOG_LEVELS.DEBUG]
const VERBOSE_AND_LOWER = [LOG_LEVELS.VERBOSE, ...DEBUG]
const INFO_AND_LOWER = [LOG_LEVELS.INFO, ...VERBOSE_AND_LOWER]
const WARN_AND_LOWER = [LOG_LEVELS.WARN, ...INFO_AND_LOWER]
const ERROR_AND_LOWER = [LOG_LEVELS.ERROR, ...WARN_AND_LOWER]

type LogLevelChecker = (level: LOG_LEVELS) => boolean
type LogLevelCheckerFactory = (levels: Array<LOG_LEVELS>) => LogLevelChecker

const checkLevel: LogLevelCheckerFactory = levels => level => levels.includes(<LOG_LEVELS>(level.toUpperCase()))

const isErrorLevelOrLower: LogLevelChecker = checkLevel(ERROR_AND_LOWER)
const isWarningLevelOrLower: LogLevelChecker = checkLevel(WARN_AND_LOWER)
const isInfoLevelOrLower: LogLevelChecker = checkLevel(INFO_AND_LOWER)
const isVerboseLevelOrLower: LogLevelChecker = checkLevel(VERBOSE_AND_LOWER)
const isDebugLevel: LogLevelChecker = checkLevel(DEBUG)

type LogWriter = (msg: string | any) => void

interface Logger {
  error: LogWriter,
  warn: LogWriter,
  info: LogWriter,
  verbose: LogWriter,
  debug: LogWriter,
  log: LogWriter,
  group: (level: LOG_LEVELS, label: string) => void,
  groupEnd: () => void,
}

type LoggerFactory = (name: string) => Logger

function now() {
  return DateTime.local().toISO()
}

enum COLORS_MAP {
  ERROR = 'color: red',
  WARN = 'color: orange',
  INFO = 'color: cyan',
  VERBOSE = 'color: bisque',
  DEBUG = 'color: khaki',
}

export const logger: LoggerFactory = name => {
  return {
    error(msg) {
      if (isErrorLevelOrLower((LOG_LEVEL as LOG_LEVELS))) {
        console.log(`%c${now()}, ERROR, ${name}, ${msg}`, COLORS_MAP.ERROR)
      }
    },

    warn(msg) {
      if (isWarningLevelOrLower((LOG_LEVEL as LOG_LEVELS))) {
        console.log(`%c${now()}, WARN, ${name}, ${msg}`, COLORS_MAP.WARN)
      }
    },

    info(msg) {
      if (isInfoLevelOrLower((LOG_LEVEL as LOG_LEVELS))) {
        console.log(`%c${now()}, INFO, ${name}, ${msg}`, COLORS_MAP.INFO)
      }
    },

    verbose(msg) {
      if (isVerboseLevelOrLower((LOG_LEVEL as LOG_LEVELS))) {
        console.log(`%c${now()}, VERBOSE, ${name}, ${msg}`, COLORS_MAP.VERBOSE)
      }
    },

    debug(msg) {
      if (isDebugLevel((LOG_LEVEL as LOG_LEVELS))) {
        console.log(`%c${now()}, DEBUG, ${name}, ${msg}`, COLORS_MAP.DEBUG)
      }
    },

    // is a way to circumvent log levels for development need
    // it is switched off in production
    log(msg) {
      if (NODE_ENV !== 'production') {
        console.log(`${now()}, CONSOLE, ${name}, ${msg}`)
      }
    },

    group(level, label) {
      console.group(`%c${label}`, COLORS_MAP[level])
    },

    groupEnd() {
      console.groupEnd()
    },
  }
}
