import Layout_de from 'common/layouts/locales/de.json'
import Layout_en from 'common/layouts/locales/en.json'
import Layout_fr from 'common/layouts/locales/fr.json'
import Layout_it from 'common/layouts/locales/it.json'
import Layout_rm from 'common/layouts/locales/rm.json'
import { Dictionary } from 'common/types/CommonTypes'
import Home_de from 'pages/Home/locales/de.json'
import Home_en from 'pages/Home/locales/en.json'
import Home_fr from 'pages/Home/locales/fr.json'
import Home_it from 'pages/Home/locales/it.json'
import Home_rm from 'pages/Home/locales/rm.json'
import Platforms_de from 'widgets/Platforms/locales/de.json'
import Platforms_en from 'widgets/Platforms/locales/en.json'
import Platforms_fr from 'widgets/Platforms/locales/fr.json'
import Platforms_it from 'widgets/Platforms/locales/it.json'
import Platforms_rm from 'widgets/Platforms/locales/rm.json'
import PrivacyPolicy_de from 'widgets/PrivacyPolicy/locales/de.json'
import PrivacyPolicy_en from 'widgets/PrivacyPolicy/locales/en.json'
import PrivacyPolicy_fr from 'widgets/PrivacyPolicy/locales/fr.json'
import PrivacyPolicy_it from 'widgets/PrivacyPolicy/locales/it.json'
import PrivacyPolicy_rm from 'widgets/PrivacyPolicy/locales/rm.json'

export type ISO_639_1 = string // check the allowed values on wikipedia, not baking this in to save time

export interface Translations {
  [ISO_639_1: string]: {
    [context: string]: Dictionary<string>,
  },
}

const resources: Translations = {
  fr: {
    Home: Home_fr,
    Layout: Layout_fr,
    Platforms: Platforms_fr,
    PrivacyPolicy: PrivacyPolicy_fr,
  },
  de: {
    Home: Home_de,
    Layout: Layout_de,
    Platforms: Platforms_de,
    PrivacyPolicy: PrivacyPolicy_de,
  },
  it: {
    Home: Home_it,
    Layout: Layout_it,
    Platforms: Platforms_it,
    PrivacyPolicy: PrivacyPolicy_it,
  },
  rm: {
    Home: Home_rm,
    Layout: Layout_rm,
    Platforms: Platforms_rm,
    PrivacyPolicy: PrivacyPolicy_rm,
  },
  en: {
    Home: Home_en,
    Layout: Layout_en,
    Platforms: Platforms_en,
    PrivacyPolicy: PrivacyPolicy_en,
  },
}

export default resources
