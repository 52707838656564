import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { configuration } from 'common/configuration'
import { logger } from 'common/helpers/logger'
import { s3AssetPath } from 'common/helpers/s3'
import { ISO_639_1 } from 'common/translations/locales'
import { TranslationsConstants } from 'common/translations/TranslationsConstants'
import routes from 'common/types/Routes'
import { Styles } from 'common/types/Styles'
import Icon from 'components/Icon/Icon'

const log = logger('NavBar.tsx')
const { Home, GDPR, Platforms, PrivacyPolicy } = routes
const { supportedLanguages } = configuration

const styles: Styles = {
  iconLabel: {
    fontSize: '1em',
    letterSpacing: '1px',
    paddingLeft: '4px',
    textTransform: 'uppercase',
  },
  labelWithIcon: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  smallTitle: {
    color: '#363636',
    fontSize: '1.5rem',
    fontWeight: '600',
    lineHeight: '1.125',
  },
}

const NavBar = () => {
  const [menuActive, setMenuActive] = useState(false)
  const { t, i18n } = useTranslation('Layout')
  const brandLogo = s3AssetPath('/images/logo_notext-1.0.0.svg')

  const toggleLanguage: (lang: ISO_639_1) => void = lang => {
    if (supportedLanguages.includes(lang)) {
      i18n.changeLanguage(lang)
    } else {
      log.warn(`language ${lang} is not supported`)
    }
  }

  const toggleMenu: () => void = () => setMenuActive(!menuActive)

  const isMenuActiveClass: string = menuActive ? 'is-active' : ''

  // TODO abstract the elements away
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" style={{ zIndex: 100 }}>
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img alt='brand logo' src={brandLogo} height="100%" width="auto"></img>
          <span style={styles.iconLabel}>{t('brand')}</span>
        </a>

        <a
          role="button"
          className={`navbar-burger burger ${isMenuActiveClass}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navMenu"
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navMenu" className={`navbar-menu level ${isMenuActiveClass}`}>
        <div className="level-left">
          <a className="navbar-item level-item" href={Home}>
            <p style={styles.labelWithIcon}>
              <Icon style={styles.smallTitle} name="home" />
              <span style={styles.iconLabel}>{t('home')}</span>
            </p>
          </a>
          <div className="navbar-item level-item has-dropdown is-hoverable">
            <p style={styles.labelWithIcon}>
              <Icon style={styles.smallTitle} name="user-secret" />
              <span style={styles.iconLabel} className="navbar-link">
                {t('gdpr')}
              </span>
            </p>
            <div className="navbar-dropdown">
              <a className="navbar-item" href={PrivacyPolicy}>
                <p style={styles.iconLabel}>{t('privacy-policy')}</p>
              </a>
              <a className="navbar-item" href={Platforms}>
                <p style={styles.iconLabel}>{t('platforms')}</p>
              </a>
            </div>
          </div>
        </div>

        <div className="level-right">
          <div className="navbar-item level-item has-dropdown is-hoverable">
            <p style={styles.labelWithIcon}>
              <Icon style={styles.smallTitle} name="language" />
              <span style={styles.iconLabel} className="navbar-link">
                {t('language')}
              </span>
            </p>
            <div className="navbar-dropdown is-right">
              {supportedLanguages.map(language => {
                const setLanguage: () => void = () => {
                  localStorage.setItem(TranslationsConstants.PREFERRED_LANGUAGE, language)
                  document.cookie = `;max-age=60;samesite=strict;lang=${language}`
                  toggleLanguage(language)
                }
                return (
                  <a key={language} className="navbar-item" onClick={setLanguage}>
                    <span style={styles.iconLabel}>{t(language)}</span>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
