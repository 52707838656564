import App from './App'
import * as serviceWorker from './serviceWorker'

import { logger, LOG_LEVELS } from 'common/helpers/logger'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'


const log = logger('index.tsx')

if (NODE_ENV !== 'production') {
  log.info('Rendering for developement...')

  // #region Environment variable configuration
  log.group(LOG_LEVELS.DEBUG, 'Configuration:')
  log.debug(`APP_VERSION: ${APP_VERSION}`)
  log.debug(`AWS_BASE_URL: ${AWS_BASE_URL}`)
  log.debug(`LOG_LEVEL: ${LOG_LEVEL}`)
  log.debug(`NODE_ENV: ${NODE_ENV}`)
  log.debug(`PUBLIC_URL: ${PUBLIC_URL}`)
  log.debug(`SUPPORTED_LANGUAGES: ${SUPPORTED_LANGUAGES}`)
  log.groupEnd()
  // #endregion

  import('@axe-core/react').then((axe) => {
    // @ts-ignore for some reason the React type doesn't match; maybe the next minor version of axe will fix that
    axe.default(React, ReactDOM, 1000)
    ReactDOM.render(
      <StrictMode>
        <App />
      </StrictMode>,
      document.getElementById('root')
    )
  })
}

else {
  log.info('Rendering for production...')
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
