import { Style } from 'common/types/Styles'
import React, { ReactElement } from 'react'


interface Props {
  name: string,
  className?: string,
  small?: boolean,
  style?: Style,
}

const Icon: (props: Props) => ReactElement<'span'> = props => {
  const { name, className, small, style } = props

  return (
    <span className={`icon ${small ? 'is-small ' : ''}`} style={style ? style : undefined}>
      <i className={`fas fa-${name} ${className || ''}`} />
    </span>
  )
}

export default Icon
