import resources from 'common/translations/locales'
import { getLanguage } from 'common/translations/useLanguage.hooks'
import { TranslationsConstants } from 'common/translations/TranslationsConstants'
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'


const query = new URLSearchParams(window.location.search)
const lng = getLanguage(navigator, localStorage, query)

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: TranslationsConstants.DEFAULT_LANGUAGE,
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
