// lw-aspect devops
import styles from './Fallback.module.scss'

import React from 'react'


export default function Fallback() {
  return (
    <div className={`container ${styles.Fallback}`}>
      <progress className='progress is-small is-info' max='100'>5%</progress>
    </div>
  )
}
