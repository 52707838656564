import React, { lazy, Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import NavBar from 'common/layouts/components/NavBar'
import i18n from 'common/translations/i18n'
import { GdprRoutes } from 'pages/gdpr/gdpr.routes'
import { HomeRoutes } from 'pages/Home/Home.routes'
import { InfoRoutes } from 'pages/infos/infos.routes'
import Fallback from 'widgets/Fallback/Fallback'

import 'App.css'

const Home = lazy(() => import('pages/Home/Home'))
const GDPR = lazy(() => import('pages/gdpr/gdpr'))
const Infos = lazy(() => import('pages/infos/Infos'))


const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
        <NavBar />
        <BrowserRouter>
          <Suspense fallback={<Fallback />}>
            <Routes>
              <Route path={HomeRoutes.ResourceClaim} element={<Home />} />
              <Route path={GdprRoutes.ResourceClaim} element={<GDPR />} />
              <Route path={InfoRoutes.ResourceClaim} element={<Infos />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
    </I18nextProvider>
  )
}

export default App
